// import React, { useState } from "react";
// import MainContainer from "../../layout/MainContainer";
// import {
//   Box,
//   Card,
//   Grid,
//   IconButton,
//   TextField,
//   Typography,
// } from "@mui/material";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import DeleteIcon from "@mui/icons-material/Delete";
// import ModeEditIcon from "@mui/icons-material/ModeEdit";

// const ManageAmenities = () => {
//   const [amenities, setAmenities] = useState<string[]>([]);
//   const [fieldNames, setFieldNames] = useState("");
//   const [editIndex, setEditIndex] = useState<number | null>(null);

//   const addAmenities = () => {
//     if (fieldNames !== "") {
//       if (editIndex !== null) {
//         // If editing, update the existing amenity at the editIndex
//         amenities[editIndex] = fieldNames;
//         setEditIndex(null); // Reset editIndex after editing
//       } else {
//         // If not editing, add a new amenity
//         setAmenities([...amenities, fieldNames]);
//       }
//     }
//     setFieldNames("");
//   };

//   const deleteAmenities = (index: number) => {
//     setAmenities((prevAmenities) =>
//       prevAmenities.filter((_, i) => i !== index)
//     );
//     // Reset editIndex if the item being edited is deleted
//     if (editIndex === index) {
//       setEditIndex(null);
//     }
//   };
//   const editAmenity = (index: number) => {
//     setFieldNames(amenities[index]);
//     setEditIndex(index);
//   };

//   return (
//     <MainContainer>
//       <div className="main_loyout">
//         <div className="dashboard">
//           <h1>Manage Amenities</h1>
//         </div>
//         <Card className="cards">
//           <Grid container spacing={2} className="view_box_list">
//             <Grid item sx={{ marginBottom: "24px" }} xs={4}>
//               <Typography className="custom_label">Amenity Name</Typography>
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "flex-start",
//                   alignItems: "center",
//                 }}
//               >
//                 <TextField
//                   hiddenLabel
//                   type={"text"}
//                   name="title"
//                   variant="outlined"
//                   value={fieldNames}
//                   onChange={(val) => {
//                     if (val.target.value === " " || val.target.value === ".") {
//                       // formik.handleChange(null);
//                     } else {
//                       setFieldNames(val.target.value);
//                     }
//                   }}
//                   fullWidth
//                   placeholder="Amenity Name"
//                 />
//                 <IconButton onClick={addAmenities}>
//                   <AddCircleIcon sx={{ color: "#3c47ea" }} />
//                 </IconButton>
//               </Box>
//             </Grid>
//             <Grid item xs={12}>
//               <Grid container spacing={2} xs={12}>
//                 {amenities?.map((item, i) => {
//                   return (
//                     <Grid item xs={4} key={i}>
//                       <Box
//                         sx={{
//                           display: "flex",
//                           justifyContent: "flex-start",
//                           alignItems: "center",
//                           flexDirection: "row",
//                         }}
//                       >
//                         <TextField
//                           hiddenLabel
//                           type={"text"}
//                           name="title"
//                           variant="outlined"
//                           className="disabled_text"
//                           value={item}
//                           fullWidth
//                           disabled
//                         />
//                         <IconButton onClick={() => deleteAmenities(i)}>
//                           <DeleteIcon sx={{ color: "#3c47ea" }} />
//                         </IconButton>
//                         <IconButton onClick={() => editAmenity(i)}>
//                           <ModeEditIcon sx={{ color: "#3c47ea" }} />
//                         </IconButton>
//                       </Box>
//                     </Grid>
//                   );
//                 })}
//               </Grid>
//             </Grid>
//           </Grid>
//         </Card>
//       </div>
//     </MainContainer>
//   );
// };

// export default ManageAmenities;

import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

const ManageAmenities = () => {
  const navigate = useNavigate();

  function createData(Name: string) {
    return { Name };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Swimmming Pool"), createData("Elevator")];

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Amenities</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar />
              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-amenities/add")}
                >
                  Add Amenity
                </Button>
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Amenity Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell>
                        <figure className="user_img">
                          <img
                            src="/static/images/user_placeholder.png"
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell>{row.Name}</TableCell>
                      <TableCell>
                        <Switch {...label} defaultChecked size="small" />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() => navigate("/manage-categories/add")}
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageAmenities;
