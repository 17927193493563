import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import { FormControl, Grid } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";

const Analytics = () => {
  const graphRevenueData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Earnings",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#3c47ea",
        backgroundColor: "#3c47ea",
      },
    ],
  };

  const graphUsersData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Users",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#3c47ea",
        backgroundColor: "#3c47ea",
      },
    ],
  };

  const graphBookingsData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Booking Requests",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#3c47ea",
        backgroundColor: "#3c47ea",
      },
    ],
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Reports and Analytics</h1>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Users
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphUsersData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Earnings
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Booking Requests
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphBookingsData} />
            </div>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default Analytics;
