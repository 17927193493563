import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  Backdrop,
  Fade,
  Typography,
  Dialog,
  DialogContent,
  FormControl,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Tabs,
  Tab,
  Switch,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";

import ReplyIcon from "@mui/icons-material/Reply";

import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Rating } from "react-simple-star-rating";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PropertyDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const location = useLocation();
  const [value, setValue] = useState<number>(0);
  const [open_gallery, setOpen_gallery] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const ownershipDocuments = [
    "/static/images/document.png",
    "/static/images/document.png",
  ];
  const amenities = [
    "Swimming Pool",
    "Parking Space",
    "Restaurant",
    "24 Hour Front Desk",
    "AC",
    "Elevator",
    "Wifi",
    "Gym",
  ];
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Property Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-listed-properties");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Property Details" {...a11yProps(0)} />
                <Tab label="Reviews and Ratings" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Card className="cards">
                <CardContent sx={{ p: 1 }}>
                  <Grid container spacing={2} className="view_box">
                    <Grid item xs={10} className="view_box_list">
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Box>
                            <Typography component="h2" className="subhdng">
                              Service Provider Details
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box>
                            <Typography component="h5">
                              Service Provider Name
                            </Typography>
                            <Typography component="p">Abdul Hussain</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box>
                            <Typography component="h5">Email</Typography>
                            <Typography component="p">
                              abdul@yopmail.com
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box>
                            <Typography component="h5">Contact No</Typography>
                            <Typography component="p">
                              +1 7767765656566
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography component="h2" className="subhdng">
                              Property Details
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box>
                            <Typography component="h5">
                              Property Title
                            </Typography>
                            <Typography component="p">
                              Bhumibandara Hotel
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box>
                            <Typography component="h5">
                              Property Category
                            </Typography>
                            <Typography component="p">Villas</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box>
                            <Typography component="h5">Location</Typography>
                            <Typography component="p">Bali</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={3}>
                          <Box>
                            <Typography component="h5">No of rooms</Typography>
                            <Typography component="p">3</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box>
                            <Typography component="h5">
                              Per hour Rental price Per Person
                            </Typography>
                            <Typography component="p">$10</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box>
                            <Typography component="h5">
                              Max time limit for a booking
                            </Typography>
                            <Typography component="p">10 mins</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box>
                            <Typography component="h5">Person Count</Typography>
                            <Typography component="p">2</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box>
                            <Typography component="h5">
                              Availability of Property
                            </Typography>
                            <Typography component="p">Available</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box>
                            <Typography component="h5">
                              Operating Hours
                            </Typography>
                            <Typography component="p">24 hrs</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Box>
                            <Typography component="h5">
                              About the property
                            </Typography>
                            <Typography component="p">
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout. The point of using
                              Lorem Ipsum is that it has a more-or-less normal
                              distribution of letters, as opposed to using
                              'Content here, content here'
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography className="subhdng">
                              Uploaded Documents
                            </Typography>
                            <Box className="docs_div">
                              {ownershipDocuments?.length
                                ? ownershipDocuments?.map((item, i) => {
                                    return (
                                      <figure className="doc_img" key={i}>
                                        <img src={item} />
                                      </figure>
                                    );
                                  })
                                : null}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography component="h2" className="subhdng">
                              Property Images
                            </Typography>
                          </Box>
                          <Box className="other_gallery">
                            <figure>
                              <img src={"/static/images/document.png"} alt="" />
                              <span
                                className="more"
                                onClick={() => setOpen_gallery(true)}
                              >
                                <img src="/images/icon_image.svg" alt="" />
                                Show all images
                              </span>
                            </figure>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Box>
                    <div className="box w_100">
                      <h3 className="subhdng">Amenities List</h3>
                      <ul style={{ paddingLeft: "20px" }}>
                        {amenities?.map((item, i) => {
                          return (
                            <li key={i} style={{ paddingBottom: "6px" }}>
                              {item}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Box>
                  <Box>
                    <div className="box w_100">
                      <h3 className="subhdng">Map View</h3>
                    </div>
                    <figure className="location_sc">
                      <img src="/static/images/map.png" alt="" />
                    </figure>
                  </Box>
                </CardContent>
              </Card>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Card sx={{ mt: 4 }} className="cards">
                <Box className="custom_tabs">
                  <h2 className="mn_hdng">Ratings and Reviews</h2>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S.No</TableCell>
                          <TableCell>User Details</TableCell>
                          <TableCell>Ratings</TableCell>
                          <TableCell>Reviews</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center">{1}</TableCell>
                          <TableCell>abdul@yopmail.com</TableCell>
                          <TableCell>
                            {" "}
                            <Rating
                              initialValue={4}
                              // onClick={(newRating: number) =>
                              //   setratingCount(newRating)
                              // }
                              iconsCount={5}
                              size={23}
                              allowHover={false}
                              readonly
                              // transition={true}
                            />
                          </TableCell>
                          <Tooltip
                            title="Lorem ipsum dolor sit amet consectetur, adipisicing elit
                        dolor sit amet consectetur. Lorem ipsum dolor sit amet
                        consectetur, adipisicing elit dolor sit amet"
                          >
                            <TableCell className="td">
                              Lorem ipsum dolor sit amet consectetur,
                              adipisicing elit dolor sit amet consectetur. Lorem
                              ipsum dolor sit amet consectetur, adipisicing elit
                              dolor sit amet consectetur.
                            </TableCell>
                          </Tooltip>
                          <TableCell>
                            <Switch {...label} defaultChecked size="small" />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Card>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <Modal
        className="modal gallery_modal"
        open={open_gallery}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open_gallery}>
          <Box className="modalBox_body">
            <Box>
              <Button
                className="close_btn"
                onClick={() => setOpen_gallery(false)}
              >
                <CloseIcon />
              </Button>
              <Swiper
                className="location_swiper gallery_swiper"
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{
                  el: ".swiper-pagination",
                  dynamicBullets: true,
                  clickable: true,
                }}
                loop
                observer
              >
                <div className="swiper_action">
                  <div className="swiper-button-prev">
                    <ArrowBackIcon />
                  </div>
                  <div className="swiper-pagination"></div>
                  <div className="swiper-button-next">
                    <ArrowForwardIcon />
                  </div>
                </div>
                {ownershipDocuments?.length
                  ? ownershipDocuments?.map((item, i) => (
                      <SwiperSlide key={i}>
                        <figure>
                          <img src={item || "/images/document.png"} alt="" />
                        </figure>
                      </SwiperSlide>
                    ))
                  : null}
              </Swiper>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </MainContainer>
  );
};

export default PropertyDetails;
