import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [legal, setLegal] = useState<string>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage CMS</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Contact Support" {...a11yProps(0)} />
              <Tab label="Privacy Policy" {...a11yProps(1)} />
              <Tab label="Terms and Conditions" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form>
                <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
