import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";

const AddSubAdmin = () => {
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");

  function createData(Module: string) {
    return { Module };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData("Manage Customers"),
    createData("Manage Points Criteria"),
    createData("Manage Round UP Configuration"),
    createData("Manage Subscription"),
    createData("Manage Email Template"),
    createData("Manage Notification"),
    createData("Reports and Analytics"),
    createData("Manage CMS"),
    createData("Manage Settings"),
  ];

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Sub-Admin</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subAdmin");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                            setFileName("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              const files = (event.target as HTMLInputElement)
                                .files;
                              if (files && files[0].type.includes("image")) {
                                setFileName(files[0].name);
                                setImage(URL.createObjectURL(files[0]));
                              } else {
                                setAlertType(0);
                                setShowAlert(true);
                                setAlertMessage(
                                  "This field only accepts images."
                                );
                              }
                            }}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">Full Name</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Name"
                    ></TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="message"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                    ></TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message"
                      variant="outlined"
                      fullWidth
                      placeholder="Phone"
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer className="table_container">
                      <Box className="heading"></Box>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="mn_hdng">Module</TableCell>
                            <TableCell className="mn_hdng">Add/Edit</TableCell>
                            <TableCell className="mn_hdng">View</TableCell>
                            <TableCell className="mn_hdng">Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, i) => (
                            <TableRow>
                              <TableCell className="mn_hdng">
                                {row.Module}
                              </TableCell>
                              <TableCell>
                                <Checkbox {...label} />
                              </TableCell>
                              <TableCell>
                                <Checkbox {...label} />
                              </TableCell>
                              <TableCell>
                                <Checkbox {...label} />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddSubAdmin;
