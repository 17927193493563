import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  CardContent,
  Grid,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputAdornment,
} from "@mui/material";

import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

const Commision = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("");

  const handleCommissionType = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  return (
    <div>
      <Box className="cards_header">
        <form>
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography className="custom_label">
                    Commision Type
                  </Typography>
                  <Select
                    className="select_div "
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    onChange={handleCommissionType}
                    displayEmpty
                    // sx={{ minWidth: "250px" }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>

                    <MenuItem value="flat">Flat</MenuItem>
                    <MenuItem value="percentage">Percentage</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography className="custom_label">Amount</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="title"
                  variant="outlined"
                  fullWidth
                  placeholder="Amount"
                  className="text_field"
                  id="title"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {type === "percentage" ? (
                          <Typography variant="caption">%</Typography>
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <div className="form_btn">
              <Button size="large" type="submit" className="btn btn_primary">
                Save
              </Button>
            </div>
          </CardContent>
        </form>
      </Box>
    </div>
  );
};

export default Commision;
