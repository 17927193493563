import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const BookingDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Booking Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-bookings");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h2" className="subhdng">
                        Customer Details
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Customer Name</Typography>
                      <Typography component="p">Abdul Hussain</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">abdul@yopmail.com</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Phone Number</Typography>
                      <Typography component="p">+971 7675454433</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h2" className="subhdng">
                        Service Provider Details
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">
                        Service Provider Name
                      </Typography>
                      <Typography component="p">John</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">abdul@yopmail.com</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Phone Number</Typography>
                      <Typography component="p">+971 7675454433</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h2" className="subhdng">
                        Booking Details
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Booking Id</Typography>
                      <Typography component="p">11565</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Property Name</Typography>
                      <Typography component="p">Bhumibandara Hotel</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Property Category</Typography>
                      <Typography component="p">Villas</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Location</Typography>
                      <Typography component="p">Bali</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">No of rooms</Typography>
                      <Typography component="p">3</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">No of guests</Typography>
                      <Typography component="p">6</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">
                        Date and Time Slot{" "}
                      </Typography>
                      <Typography component="p">
                        <p>25/12/2023</p>
                        <p>11:00-18:00</p>
                      </Typography>
                    </Box>
                  </Grid>

                  {state === "CANCELLED" ? (
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Refund Status</Typography>
                        <Typography component="p">Completed</Typography>
                      </Box>
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">About the property</Typography>
                      <Typography component="p">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here'
                      </Typography>
                    </Box>
                  </Grid>

                  {state === "ONGOING" ? (
                    <>
                      {" "}
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            padding: "20px 0 0",
                            fontSize: "20px",
                            color: "#1d1d1d",
                            fontWeight: "700",
                          }}
                          component="h2"
                        >
                          Accept/Reject Booking
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                        >
                          Accept
                        </Button>
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                        >
                          Reject
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Button className="btn btn_primary">
                        Generate Invoice
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default BookingDetails;
