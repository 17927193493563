import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Select,
  FormControl,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState } from "react";
import SearchBar from "../../components/SearchBar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";

const ServiceProviderEarnings = () => {
  const navigate = useNavigate();
  const [serviceProvider, setServiceProvider] = useState("");

  const handleServiceProvider = (event: SelectChangeEvent) => {
    setServiceProvider(event.target.value as string);
  };
  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }

  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "+971056734321",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10",
      "600 SAR"
    ),
  ];

  return (
    <div>
      <Box className="cards_header">
        {/* <SearchBar
        // searchTerm={searchTerm}
        // setDebouncedSearchTerm={setDebouncedSearchTerm}
        // value={searchTerm}
        // onCross={() => setSearchTerm("")}
        // onChange={(val: any) => {
        //   if (isValidInput(val.target.value)) {
        //     setSearchTerm(val.target.value);
        //   }
        // }
        // }
        /> */}
        <FormControl sx={{ width: "30%" }}>
          <Select
            className="select_div"
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={serviceProvider}
            inputProps={{
              "aria-label": "Without label",
            }}
            displayEmpty
            onChange={handleServiceProvider}
          >
            <MenuItem value="" disabled>
              Select Service Provider
            </MenuItem>
            <MenuItem value="1">Tushar</MenuItem>
            <MenuItem value="2">Jyoti</MenuItem>
            <MenuItem value="3">Divya</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TableContainer className="table_container">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Property Name</TableCell>
              <TableCell align="center">Service Provider Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Total Earnings</TableCell>
              <TableCell align="center"> View Booking History</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={row.name}>
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell align="center"> Bhumibandara Hotel</TableCell>
                <TableCell align="center">John</TableCell>
                <TableCell align="center">john@yopmail.com</TableCell>
                <TableCell align="center">$100</TableCell>
                <TableCell align="center">
                  <Box className="table_actions">
                    <IconButton
                      onClick={() => navigate("/manage-bookings/details")}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ServiceProviderEarnings;
