import { useNavigate } from "react-router-dom";
import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import MainContainer from "../../../layout/MainContainer";
import "../Users.scss";
import moment from "moment";
import { ChatDetail } from "../../../components";

const UserDetails = () => {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View User Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manageUsers");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure className="profile_img">
                  <img src="/static/images/user_placeholder.png" alt="" />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Name</Typography>
                      <Typography component="p">Abdul67</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">+971056734321</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        abdulhussian67@gmail.com
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Account Status</Typography>
                      <Typography component="p">Active</Typography>
                    </Box>
                  </Grid>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      Booking Details
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            Total bookings so far
                          </Typography>
                          <Typography component="p">10</Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            Current Bookings
                          </Typography>
                          <Typography component="p">3</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            Bookings Cancelled
                          </Typography>
                          <Typography component="p">3</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            Bookings Completed
                          </Typography>
                          <Typography component="p">1</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <h2 className="mn_hdng">Chat History</h2>
            <div className="pMain cht_revrs" style={{ alignItems: "normal" }}>
              <div className="pLeft" style={{ padding: 10 }}>
                <ul className="chat">
                  <li>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <figure>
                        <img
                          src={"/static/images/user_placeholder.png"}
                          alt=""
                        />
                      </figure>
                      <h5>{moment().format("hh:mm A")}</h5>
                      <div className="contnt">
                        <h3>John</h3>
                        <p>Hiii</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="pRyt" style={{ padding: 30 }}>
                <ChatDetail />
              </div>
            </div>
          </Box>
        </Card>

        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <h2 className="mn_hdng">Booking History</h2>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Booking Id</TableCell>
                    <TableCell>Booking Date</TableCell>
                    <TableCell>Amount</TableCell>

                    <TableCell>Property Name</TableCell>

                    <TableCell>Location</TableCell>

                    <TableCell>Check In</TableCell>
                    <TableCell>Check Out</TableCell>
                    <TableCell>No of Guests</TableCell>
                    <TableCell>Booking Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{1}</TableCell>
                    <TableCell align="center">1175</TableCell>
                    <TableCell>22/12/2023</TableCell>
                    <TableCell>$100</TableCell>
                    <TableCell>Bhumibandara Hotel</TableCell>{" "}
                    <TableCell>Bali</TableCell>
                    <TableCell>Thu,22 Dec 2023 (14:00)</TableCell>
                    <TableCell>Fri,23 Dec 2023 (14:00)</TableCell>
                    <TableCell>3</TableCell>
                    <TableCell>Accepted</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default UserDetails;
