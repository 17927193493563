import React, { ChangeEvent, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Input,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const AddServiceProvider = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [age, setAge] = useState("");

  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          {state?.type === "edit" ? (
            <h1 className="mn_hdng">Edit Service Provider</h1>
          ) : (
            <h1 className="mn_hdng">Add Service Provider</h1>
          )}
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-service-provider");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className="custom_label">Image</Typography>
                  {image ? (
                    <div className="upload_image_preview">
                      <CardMedia component="img" image={image} alt="photo" />
                      <CancelIcon
                        onClick={() => {
                          setImage("");
                          setFileName("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const files = (event.target as HTMLInputElement)
                              .files;
                            if (files && files[0].type.includes("image")) {
                              setFileName(files[0].name);
                              setImage(URL.createObjectURL(files[0]));
                            } else {
                              setAlertType(0);
                              setShowAlert(true);
                              setAlertMessage(
                                "This field only accepts images."
                              );
                            }
                          }}
                        />
                        <Button component="span" className="upload_image_btn">
                          <img
                            src="/static/images/user_placeholder.png"
                            alt=""
                          />
                          <CameraAltIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">First Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="First Name"
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Last Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="Last Name"
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Email</Typography>
                  <TextField
                    hiddenLabel
                    type={"email"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Email"
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Phone Number</Typography>
                  <PhoneInput
                    country={"us"}
                    placeholder="0 (000) 000-000"
                    enableSearch={true}
                    inputStyle={{ width: "100%" }}
                  />
                </Grid>
                {/* <Grid item xs={4}>
                    <Typography className="custom_label">Address</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="address"
                      variant="outlined"
                      fullWidth
                      placeholder="Address"
                    ></TextField>
                  </Grid> */}
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddServiceProvider;
