import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MainContainer from "../../../layout/MainContainer";
import { ChatDetail, DocumentViewer } from "../../../components";
import moment from "moment";

const ServiceProviderDetails = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [modalType, setModalType] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Service Provider Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-service-provider");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure className="profile_img">
                  <img src="/static/images/user_placeholder.png" alt="" />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Name</Typography>
                      <Typography component="p">Abdul67</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">+971056734321</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        abdulhussian67@gmail.com
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Account Status</Typography>
                      <Typography component="p">Active</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Service Provider Status
                      </Typography>
                      <Typography component="p">Accepted</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Location</Typography>
                      <Typography component="p">Bali</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Document Type</Typography>
                      <Typography component="p">Passport</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Documents</Typography>
                      <Box className="docs_div">
                        <figure>
                          <VisibilityIcon onClick={() => setOpen(true)} />

                          <img src="/static/images/document.png" alt="" />
                        </figure>
                        <figure>
                          <VisibilityIcon onClick={() => setOpen(true)} />
                          <img src="/static/images/document.png" alt="" />
                        </figure>
                      </Box>
                    </Box>
                  </Grid>
                  {state.userStatus === "PENDING" ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            padding: "20px 0 0",
                            fontSize: "20px",
                            color: "#1d1d1d",
                            fontWeight: "700",
                          }}
                          component="h2"
                        >
                          Approval
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                        >
                          Accept
                        </Button>
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                        >
                          Reject
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <h2 className="mn_hdng">Chat History</h2>
            <div className="pMain cht_revrs" style={{ alignItems: "normal" }}>
              <div className="pLeft" style={{ padding: 10 }}>
                <ul className="chat">
                  <li>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <figure>
                        <img
                          src={"/static/images/user_placeholder.png"}
                          alt=""
                        />
                      </figure>
                      <h5>{moment().format("hh:mm A")}</h5>
                      <div className="contnt">
                        <h3>John</h3>
                        <p>Hiii</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="pRyt" style={{ padding: 30 }}>
                <ChatDetail />
              </div>
            </div>
          </Box>
        </Card>

        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <h2 className="mn_hdng">Booking History</h2>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>

                    <TableCell align="center">Booking Id</TableCell>
                    <TableCell>Booking Date</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Property Name</TableCell>

                    <TableCell>Location</TableCell>

                    <TableCell>Check In</TableCell>
                    <TableCell>Check Out</TableCell>
                    <TableCell>No of Guests</TableCell>
                    <TableCell>Booking Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{1}</TableCell>
                    <TableCell>11702</TableCell>
                    <TableCell>22/12/2023</TableCell>
                    <TableCell>$100</TableCell>
                    <TableCell>Bhumibandara Hotel</TableCell>{" "}
                    <TableCell>Bali</TableCell>
                    <TableCell>Thu,22 Dec 2023 (14:00)</TableCell>
                    <TableCell>Fri,23 Dec 2023 (14:00)</TableCell>
                    <TableCell>3</TableCell>
                    <TableCell>Accepted</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
        <DocumentViewer open={open} setOpen={setOpen} />
      </div>
    </MainContainer>
  );
};

export default ServiceProviderDetails;
